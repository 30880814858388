<script>
  import "dracula-prism/css/dracula-prism.css";
  import ClipboardJS from "clipboard";
  import Prism from "prismjs";

  export let emoji;
  let output;

  new ClipboardJS("#output");

  $: svg = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><text y=".9em" font-size="90">${emoji}</text></svg>`
  );

  $: link = `<link rel="shortcut icon" href="data:image/svg+xml,${svg}" type="image/svg+xml">`;

  $: highlighted = Prism.highlight(link, Prism.languages.html, "html");

  $: {
    if (emoji) {
      document.head.firstElementChild.outerHTML = link;
      if (output) output.dataset.emoji = emoji;
      console.log(emoji);
    }
  }
</script>

{#if emoji}
  <div
    bind:this={output}
    id="output"
    data-clipboard-action="copy"
    data-clipboard-target="#output"
  >
    {@html highlighted}
  </div>
{:else}
  <h3>Choose an emoji</h3>
{/if}

<style>
  h3 {
    color: var(--foreground);
  }

  #output {
    background-color: var(--background);
    padding: 1rem;
    word-break: break-all;
    cursor: pointer;
    border-radius: 0.25rem;
    width: 100%;
    font-family: "Source Code Pro", monospace;
    font-size: 0.9rem;
    transform: scale(1);
    transition: 0.2s ease-in-out;
    position: relative;
  }

  #output:hover {
    background-color: var(--selection);
  }

  #output:active {
    transform: scale(0.9);
  }

  #output::after {
    position: absolute;
    content: attr(data-emoji);
    right: -0.5rem;
    top: -0.5rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
  }
</style>
