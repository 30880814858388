<script>
  import "emoji-picker-element";
  import Favicon from "./Favicon.svelte";

  let emoji;

  const chooseEmoji = (e) => {
    let next = e.detail.unicode;
    if (next != emoji) emoji = next;
  };
</script>

<main>
  <Favicon {emoji} />
</main>

<aside>
  <emoji-picker on:emoji-click={chooseEmoji} />
</aside>

<style>
  main,
  aside {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  main {
    min-height: 250px;
    max-width: 600px;
    padding: 1rem 1rem 0;
  }

  emoji-picker {
    --num-columns: 6;
    --background: var(--selection);
    --border-color: var(--pink);
    --input-font-color: var(--foreground);
    --input-border-color: var(--foreground);
    --input-placeholder-color: var(--comment);
    --indicator-color: var(--cyan);
    height: 350px;
  }
</style>
